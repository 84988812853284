<script setup lang='ts'>
const route = useRoute();

const homeUrl = computed<string>(() => '/');

const hideTopBarLogin = computed<boolean>(() => route.name === 'experiences-areaId-serviceId-confirm');
</script>

<template>
    <div class="layout-main-wrapper font-primary font-normal flex flex-col flex-1 overflow-x-hidden">
        <AppTopbar class="md:block" :home-url="homeUrl" :hide-login="hideTopBarLogin" :hide-area-select="true" />
        <div class="layout-main-container w-full pr-0 pl-0">
            <div class="layout-main h-full">
                <slot />
            </div>
        </div>

        <AppFooter class="hidden md:block" />
    </div>
</template>

<style lang="scss" scoped>

</style>
